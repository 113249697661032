import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/pages/index/Header"
import Mission from "../components/pages/index/Mission"
import Expertise from "../components/pages/index/Expertise"
import LatestProjects from "../components/pages/index/LatestProjects"
import WhyUs from "../components/pages/index/WhyUs"
import Contact from "../components/pages/index/Contact"

const IndexPage = () => (
  <Layout>
    <Seo
      titleOverride="Tactic Apps: Web & Mobile App Development For Startups"
      keywords={[
        `web development`,
        `mobile development`,
        `website development`,
        `application development`,
      ]}
    />
    <Header />
    <main>
      <Mission />
      <Expertise />
      <LatestProjects />
      <WhyUs />
      <Contact heading="Need a custom built application?" />
    </main>
  </Layout>
)

export default IndexPage
