import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import RightIcon from "../../../assets/icons/chevron-right.svg"

const LatestProjects = () => (
  <StaticQuery
    query={graphql`
      {
        project1: file(
          relativePath: { eq: "work/websites/tackis-project.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 700, layout: CONSTRAINED)
          }
        }
        project2: file(relativePath: { eq: "work/web/graphic-color.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 700, layout: CONSTRAINED)
          }
        }
        project3: file(relativePath: { eq: "work/web/devlink-project.jpeg" }) {
          childImageSharp {
            gatsbyImageData(width: 700, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <section id="latest-projects" className="py-5">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-9 m-auto">
              <h2>Latest Projects</h2>
              <p className=" text-muted fw-l pb-4">
                Below is a preview of our most recent development projects. You
                can view a more extensive list of detailed case studies{" "}
                <Link className="btn-inline" to="/work">
                  here
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="row">
            <Link to="/work/websites/tackis" className="col-md-4 mb-4">
              <div className="position-relative">
                <GatsbyImage
                  image={data.project1.childImageSharp.gatsbyImageData}
                  alt="Portfolio project 1"
                  style={{
                    borderRadius: "3px",
                    boxShadow: "0 1rem 1rem rgba(0,0,0, 0.2)",
                  }}
                />
                <div className="latest-projects-description text-left">
                  <h6 className="text-primary mb-0 text-uppercase">Website</h6>
                  <h3 className="text-white">Tackis</h3>
                </div>
              </div>
            </Link>
            <Link
              to="/work/web-applications/graphic-color"
              className="col-md-4 mb-4"
            >
              <div className="position-relative">
                <GatsbyImage
                  image={data.project2.childImageSharp.gatsbyImageData}
                  alt="Portfolio project 2"
                  style={{
                    borderRadius: "3px",
                    boxShadow: "0 1rem 1rem rgba(0,0,0, 0.2)",
                  }}
                />
                <div className="latest-projects-description text-left">
                  <h6 className="text-primary mb-0 text-uppercase">
                    Web Development
                  </h6>
                  <h3 className="text-white">Graphic Color</h3>
                </div>
              </div>
            </Link>
            <Link to="/work/web-applications/devlink" className="col-md-4 mb-4">
              <div className="position-relative">
                <GatsbyImage
                  image={data.project3.childImageSharp.gatsbyImageData}
                  alt="Portfolio project 3"
                  style={{
                    borderRadius: "3px",
                    boxShadow: "0 1rem 1rem rgba(0,0,0, 0.2)",
                  }}
                />
                <div className="latest-projects-description">
                  <h6 className="text-primary text-uppercase mb-0">
                    Web Development
                  </h6>
                  <h3 className="text-white">Developer Link</h3>
                </div>
              </div>
            </Link>
          </div>
          <Link to="/work/" className="btn btn-info text-uppercase mt-4">
            <span>See our work </span>
            <RightIcon />
          </Link>
        </div>
      </section>
    )}
  />
)

export default LatestProjects
