import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import HTML5Icon from "../../../assets/icons/html5.svg"
import SASSIcon from "../../../assets/icons/sass.svg"
import TailwindCSSIcon from "../../../assets/icons/tailwindcss.svg"
import ReactIcon from "../../../assets/icons/reactIcon.svg"
import NodeIcon from "../../../assets/icons/node-js.svg"
import MongoDBIcon from "../../../assets/icons/mongodb.svg"
import RightIcon from "../../../assets/icons/chevron-right.svg"

const Expertise = () => (
  <StaticQuery
    query={graphql`
      {
        expertise: file(relativePath: { eq: "expertise.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 900, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <section id="expertise" className="pt-3 pb-5">
        <div className="container">
          <div className="row position-relative">
            <div className="square-bg" />
            <div className="col-lg-6">
              <div className="pb-4 d-lg-none">
                <h6 className="text-uppercase pb-2 text-muted">
                  Technical Expertise
                </h6>
                <GatsbyImage
                  image={data.expertise.childImageSharp.gatsbyImageData}
                  alt="Expertise"
                  style={{
                    padding: "10px",
                    border: "10px solid #fff",
                    borderRadius: "5px",
                    boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                  }}
                />
              </div>
              <h6 className="text-uppercase d-none d-md-block text-muted">
                Technical Expertise
              </h6>
              <p className="h2 text-gr fw-l">
                We use the <span className="text-primary">latest</span>{" "}
                frameworks and technologies to develop{" "}
                <span className="text-primary">modern, scalable</span>{" "}
                applications.
              </p>
              <div className="row no-gutters justify-content-between align-items-center pt-3 pb-4">
                <div className="col-6 col-lg-4 p-none text-muted pb-3">
                  <div className="technologyGroup d-flex align-items-center justify-content-start">
                    <div className="col-3 p-none">
                      <HTML5Icon
                        className="technologyIcon mr-1"
                        style={{
                          fill: "currentColor",
                          height: "32px",
                          width: "32px",
                        }}
                      />
                    </div>
                    <div className="technologyName col-9">HTML5</div>
                  </div>
                </div>
                <div className="col-6 col-lg-4 p-none text-muted pb-3">
                  <div className="technologyGroup d-flex align-items-center justify-content-start">
                    <div className="col-3 p-none">
                      <SASSIcon
                        className="technologyIcon mr-1"
                        style={{
                          fill: "currentColor",
                          height: "32px",
                          width: "32px",
                        }}
                      />
                    </div>
                    <div className="technologyName col-9">SASS & CSS3</div>
                  </div>
                </div>
                <div className="col-6 col-lg-4 p-none text-muted pb-3">
                  <div className="technologyGroup d-flex align-items-center justify-content-start">
                    <div className="col-3 p-none">
                      <TailwindCSSIcon
                        className="technologyIcon mr-1"
                        style={{
                          fill: "currentColor",
                          height: "32px",
                          width: "32px",
                        }}
                      />
                    </div>
                    <div className="technologyName col-9">TailwindCSS</div>
                  </div>
                </div>
                <div className="col-6 col-lg-4 p-none text-muted pb-3">
                  <div className="technologyGroup d-flex align-items-center justify-content-start">
                    <div className="col-3 p-none">
                      <ReactIcon
                        className="technologyIcon mr-1"
                        style={{
                          fill: "currentColor",
                          height: "32px",
                          width: "32px",
                        }}
                      />
                    </div>
                    <div className="technologyName col-9">
                      React & React Native
                    </div>
                  </div>
                </div>
                <div className="col-6 col-lg-4 p-none text-muted pb-3">
                  <div className="technologyGroup d-flex align-items-center justify-content-start">
                    <div className="col-3 p-none">
                      <NodeIcon
                        className="technologyIcon mr-1"
                        style={{
                          fill: "currentColor",
                          height: "32px",
                          width: "32px",
                        }}
                      />
                    </div>
                    <div className="technologyName col-9">
                      Node.js & GraphQL
                    </div>
                  </div>
                </div>
                <div className="col-6 col-lg-4 p-none text-muted pb-3">
                  <div className="technologyGroup d-flex align-items-center justify-content-start">
                    <div className="col-3 p-none">
                      <MongoDBIcon
                        className="technologyIcon mr-1"
                        style={{
                          fill: "currentColor",
                          height: "32px",
                          width: "32px",
                        }}
                      />
                    </div>
                    <div className="technologyName col-9">NoSQL MongoDB</div>
                  </div>
                </div>
              </div>
              <Link to="/services/" className="btn btn-info">
                <span>Learn More About Our Services</span>
                <RightIcon />
              </Link>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <GatsbyImage
                image={data.expertise.childImageSharp.gatsbyImageData}
                alt="Expertise"
                style={{
                  padding: "10px",
                  border: "10px solid #fff",
                  borderRadius: "5px",
                  boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    )}
  />
)

export default Expertise
