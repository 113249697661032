import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Wave from "../../../assets/icons/wave.svg"

const Header = () => (
  <StaticQuery
    query={graphql`
      {
        heroImage: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={(data) => (
      <header
        id="main-header"
        className="pb-5 position-relative overflow-hidden"
      >
        <div className="container ">
          <GatsbyImage
            image={data.heroImage.childImageSharp.gatsbyImageData}
            alt=""
            loading="eager"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
          <div className="darker-overlay" />
          <Wave className="wave" />
          <div className="row main-header align-items-center">
            <div className="col-md-10 col-lg-7">
              <h6 className="text-primary text-uppercase">What we do</h6>
              <h1 className="text-white pb-1">
                Web & Mobile Application Development{" "}
                <span className="text-primary">For Startups</span>
              </h1>
              <div className="sub-heading mb-4" style={{ color: "#afb8c6" }}>
                From idea validation to application release, let us help you
                build an experience your end users will love.
              </div>
              <Link to="/contact/" className="btn btn-info text-uppercase mt-2">
                Work with us
              </Link>
            </div>
          </div>
        </div>
      </header>
    )}
  />
)

export default Header
